import React from "react";
import './MainInfo.css';


const MainInfo = () => {

    return (
        <div className="main-info">
            <h1 className="main-info-h1">oscar daniel<br />piñeros hernandez</h1>
            <p className="main-info-p">Ingeniero de Sistemas & Desarrollador Front-End</p>
        </div>
    );
}


export default MainInfo;