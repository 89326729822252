import React from "react";
import NavHeader from "./NavHeader";



const NavCenter = () => {

    return (
        <NavHeader />
    )
}


export default NavCenter;